// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-husky-src-pages-404-tsx": () => import("./../../../../gatsby-theme-husky/src/pages/404.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-pages-404-tsx" */),
  "component---gatsby-theme-husky-src-templates-article-details-page-article-details-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/ArticleDetailsPage/ArticleDetailsPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-article-details-page-article-details-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-education-page-education-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/EducationPage/EducationPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-education-page-education-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-faq-page-faq-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/FaqPage/FaqPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-faq-page-faq-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-history-page-history-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/HistoryPage/HistoryPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-history-page-history-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-home-page-home-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/HomePage/HomePage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-home-page-home-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-insect-detail-page-insect-detail-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/InsectDetailPage/InsectDetailPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-insect-detail-page-insect-detail-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-insectipedia-page-insectipedia-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/InsectipediaPage/InsectipediaPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-insectipedia-page-insectipedia-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-lds-page-lds-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/LdsPage/LdsPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-lds-page-lds-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-paia-manual-page-paia-manual-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/PaiaManualPage/PaiaManualPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-paia-manual-page-paia-manual-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-product-details-page-product-details-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/ProductDetailsPage/ProductDetailsPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-product-details-page-product-details-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-sitemap-page-sitemap-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/SitemapPage/SitemapPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-sitemap-page-sitemap-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-solutions-page-solutions-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/SolutionsPage/SolutionsPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-solutions-page-solutions-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-where-to-buy-page-where-to-buy-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/WhereToBuyPage/WhereToBuyPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-where-to-buy-page-where-to-buy-page-tsx" */)
}

